import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoaderService } from './../../services/loader.service';
import { Component, OnInit } from '@angular/core';
import { FragmentService } from '../../services/fragment.service';
import { environment } from 'environments/environment';
import { FirstPageLoadService } from 'app/services/firstSiteLoad.service';
import { MetaService } from 'app/services/meta.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  subscription: Subscription;
  loadingContent = environment.envName === 'development' ? false : true;
  showGlobalFooter = true;
  showHeaderandFooter = false;
  globalFooterOffset = false;
  isSmallFooter = false;
  footerFor;
  constructor(
    public fragmentService: FragmentService,
    public loaderService: LoaderService,
    private firstPageLoadService: FirstPageLoadService,
    private router: Router,
    private metaService: MetaService
  ) {}

  ngOnInit() {
    this.loaderService.loadingContent.subscribe((loading: boolean) => {
      this.showHeaderandFooter = !loading;
    });
    // Universal handler for loader. This handles the loader for all markdown content based pages
    // this.router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationEnd) {
    //     this.loadingContent = this.firstPageLoadService.loadContent(window.location.pathname);
    //   }
    // });
    // Show header and footer by default
    // this.headerFooterService.showHeaderFooter.next(true);
    // this.headerFooterService.showHeaderFooter.subscribe((bool) => {
    //   this.showHeaderFooter = bool;
    // });
    /**
     * Added logic to show footer in content container width,
     * as per convergence api overview page
     */
    this.metaService.metadata.subscribe((meta) => {
      if (
        meta &&
        meta.template &&
        (meta.template === 'public' ||
          meta.template === 'api-overview' ||
          meta.template === 'product-landing' ||
          meta.template === 'doc-page' ||
          meta.template === 'redoc' ||
          meta.template === 'sdk')
      ) {
        this.showGlobalFooter = false;
      } else {
        this.showGlobalFooter = true;
      }

      this.globalFooterOffset = meta && meta.template && meta.template === 'sdk';

      this.isSmallFooter =
        meta.title === 'Platforms' || meta.title === 'Enterprise' || meta.title === 'Smb' || meta.title === 'Payfac';
      this.footerFor =
        meta.title === 'Platforms'
          ? 'platforms'
          : meta.title === 'Enterprise'
          ? 'enterprise'
          : meta.title === 'Smb'
          ? 'smb'
          : meta.title === 'Payfac'
          ? 'payfac'
          : meta.title === 'Financial Institution'
          ? 'financial-institution'
          : meta.title === 'Independent Sales Organization'
          ? 'independent-sales-organization'
          : meta.title === 'Independent Software Vendor'
          ? 'independent-software-vendor'
          : '';
    });
  }
}
