<!-- <app-header [route]="pageRoute"></app-header> -->
<div class="page wpdh-page-wrapper">
  <notification *ngIf="isIEOrEdge" title="Warning" class="-colorAccentYellow">
    You are using an unsupported browser. Please switch to a modern browser such as Chrome, Firefox, or Safari for the intended experience.
  </notification>

  <div *ngIf="hasContent" 
  [ngClass]="meta?.title === 'Platforms' 
  ? '-platforms-wrapper' : meta?.title === 'Enterprise' 
  ? '-enterprise-wrapper' : meta?.title === 'Smb' 
  ? '-smb-wrapper' : meta?.title === 'Payfac' 
  ? '-payfac-wrapper' : meta?.title === 'Financial Institution' 
  ? '-financial-institution-wrapper' : meta?.title === 'Independent Sales Organization' 
  ? '-independent-sales-organization-wrapper' : meta?.title === 'Independent Software Vendor' 
  ? '-independent-software-vendor-wrapper' : ''">
    <div class="-spec-page-wrapper" *ngIf="meta.template === globals.TEMPLATE_TYPES.REDOC">
      <app-spec-hoster
        [body]="body"
        [categories]="categories"
        [pageRoute]="pageRoute"
        [routeRedocSubscription]="routeRedocSubscription"
        [isApiMaticSpec]="isApiMaticSpec">
      </app-spec-hoster>
      <app-spec-footer *ngIf="!isApiMaticSpec"></app-spec-footer>
      <app-footer *ngIf="isApiMaticSpec" mode="null"></app-footer>
    </div>
    <!-- <div class="-spec-page-wrapper-apimatic" *ngIf="meta.template === globals.TEMPLATE_TYPES.SDK_PAGE">
      <app-sdks
        [body]="body"
        [categories]="categories"
        [pageRoute]="pageRoute"
        [routeRedocSubscription]="routeRedocSubscription">
      </app-sdks> -->
      <!-- <app-spec-footer></app-spec-footer> -->
    <!-- </div> -->
    <app-product
      *ngIf="meta.template === globals.TEMPLATE_TYPES.PRODUCT"
      [body]="body"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-product>
    <apis
      *ngIf="meta.template === globals.TEMPLATE_TYPES.APIS_PAGE"
      [body]="body"
      [meta]="meta"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </apis>
    <div class="-doc-page-wrapper" *ngIf="meta.template === globals.TEMPLATE_TYPES.PUBLIC || meta.template === globals.TEMPLATE_TYPES.PRODUCT_LANDING || meta.template === globals.TEMPLATE_TYPES.API_OVERVIEW || meta.template === globals.TEMPLATE_TYPES.DOC_PAGE">
      <left-nav
        class="-left-nav-container"
        *ngIf="meta.template !== globals.TEMPLATE_TYPES.PUBLIC"
        [meta]="meta"
        [isSecNav]="isSecNav"
        [sidebarShow]="sidebarShow"
        [ngClass]="{'-left-nav-doc-container': meta.template === 'doc-page' , '-hide-sec-nav': !isSecNav, '-overlay-sec-nav': sidebarShow }"
        (sidebarEvent)="sidebarShowHandler($event)"></left-nav>
      <div class="-content" [ngClass]="{'-product-content': meta.template === 'doc-page', '-hide-sec-nav': !isSecNav }" #content>
        <div class="-docpage-content-container" [ngClass]="{'-public-content-container': (meta.template === 'public' && isDocIndex), '-product-landing-container': (meta.template === globals.TEMPLATE_TYPES.PRODUCT_LANDING)}">
          <app-public
            *ngIf="meta.template === globals.TEMPLATE_TYPES.PUBLIC && !contactSales "
            [body]="body"
            [meta]="meta"
            [categories]="categories"
            [pageRoute]="pageRoute">
          </app-public>
          <app-product-landing
            *ngIf="meta.template === globals.TEMPLATE_TYPES.PRODUCT_LANDING && !contactSales "
            [body]="body"
            [meta]="meta"
            [categories]="categories"
            [pageRoute]="pageRoute">
          </app-product-landing>
          <app-api-overview
            *ngIf="meta.template === globals.TEMPLATE_TYPES.API_OVERVIEW && !contactSales"
            [body]="body"
            [meta]="meta"
            [categories]="categories"
            [pageRoute]="pageRoute">
          </app-api-overview>
          <app-doc
            *ngIf="meta.template === globals.TEMPLATE_TYPES.DOC_PAGE && !contactSales "
            [body]="body"
            [categories]="categories"
            [pageRoute]="pageRoute">
          </app-doc>
          <app-contact-sales
          *ngIf="(meta.template === globals.TEMPLATE_TYPES.API_OVERVIEW || meta.template === globals.TEMPLATE_TYPES.DOC_PAGE) && contactSales"
          ></app-contact-sales>
        </div>    
        <app-footer *ngIf="meta.template !== globals.TEMPLATE_TYPES.PUBLIC && meta.template !== globals.TEMPLATE_TYPES.PRODUCT_LANDING" mode="secondary"></app-footer>
      </div>
    </div>
    <div *ngIf="meta.template === globals.TEMPLATE_TYPES.PRODUCT_LANDING">
      <app-footer  mode="null"></app-footer>
    </div>
    <app-basic
      *ngIf="meta.template !== globals.TEMPLATE_TYPES.PUBLIC && meta.template !== globals.TEMPLATE_TYPES.PRODUCT && meta.template !== globals.TEMPLATE_TYPES.REDOC && meta.template !== globals.TEMPLATE_TYPES.APIS_PAGE && meta.template !== globals.TEMPLATE_TYPES.PRODUCT_LANDING && meta.template !== globals.TEMPLATE_TYPES.API_OVERVIEW && meta.template !== globals.TEMPLATE_TYPES.DOC_PAGE && meta.template !== globals.TEMPLATE_TYPES.HOMEPAGE"
      [body]="body"
      [meta]="meta"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-basic>
    <app-homepage
      *ngIf="meta.template === globals.TEMPLATE_TYPES.HOMEPAGE"
      [body]="body"
      [meta]="meta"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-homepage>
    <app-footer *ngIf="meta.template === globals.TEMPLATE_TYPES.PUBLIC" mode="primary"></app-footer>
  </div>
  
  <!-- <div *ngIf="hasContent" [ngSwitch]="meta.template">
    <app-redoc
      *ngSwitchCase="globals.TEMPLATE_TYPES.REDOC"
      [body]="body"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-redoc> 
    <div [ngClass]="{'doc-page-nav': meta.template === 'doc-page', 'api-page-nav' : meta.template === 'api-overview' }" class="-vertical-nav" [body]="body" [categories]="categories"
      [pageRoute]="pageRoute" #nav>
        <nav-vertical-updated slideNav="slideNav"></nav-vertical-updated>
    </div>
    <app-doc
      *ngSwitchCase="globals.TEMPLATE_TYPES.DOC_PAGE"
      [body]="body"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-doc>
    <app-api-overview
      *ngSwitchCase="globals.TEMPLATE_TYPES.API_OVERVIEW"
      [body]="body"
      [meta]="meta"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-api-overview>
    <app-product
      *ngSwitchCase="globals.TEMPLATE_TYPES.PRODUCT"
      [body]="body"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-product>
    <apis
      *ngSwitchCase="globals.TEMPLATE_TYPES.APIS_PAGE"
      [body]="body"
      [meta]="meta"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </apis>
    <app-homepage
      *ngSwitchCase="globals.TEMPLATE_TYPES.HOMEPAGE"
      [body]="body"
      [meta]="meta"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-homepage>
    <app-basic
      *ngSwitchDefault
      [body]="body"
      [meta]="meta"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-basic> -->
    <!-- <app-lead-gen
      *ngSwitchCase="'contact-sales'">
    </app-lead-gen> -->
    <!-- <app-dashboard
      *ngSwitchCase="'dashboard'">
    </app-dashboard> -->
    <!-- <lead-confirm
      *ngSwitchCase="'contact-confirmation'">
    </lead-confirm> -->
    <!-- <app-four-oh-four
      *ngSwitchCase="'404'">
    </app-four-oh-four>
    <app-four-oh-three
      *ngSwitchCase="'403'">
    </app-four-oh-three> -->
  <!-- </div> -->

  <div *ngIf="!hasContent">Content failed to load!</div>

  <div *ngIf="getapikeyInfoModal">
    <modal 
      [visible]="true" 
      (visibleChange)="closeInfoModal()" 
      [secondaryModal]="true"
      [title]="'Not logged in !'">
      <div>
      <p class="-fontSmall -colorAccentGrey -description-padding">
          You must login to access this feature
      </p>
      <div>
          <wpbutton type="submit" to="/account/login">Login</wpbutton>
          <wpbutton type="submit" to="/account/register">Create a free account</wpbutton>
      </div>
      </div>
    </modal>
  </div>
</div>
