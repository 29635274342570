<div class="-docheader-sec-nav -container">
  <ul>
    <li *ngFor="let navigationItem of navigationTabs" [ngClass]="{ 'is-active': (getActiveClass(navigationItem) || (navigationItem.active)) }">
      <app-link [to]="navigationItem.path" [linkmode]="navigationItem.mode">
        {{navigationItem.title}}
      </app-link>
    </li>
  </ul>
  <div class="-container-right">
    <releasenotes-subscription *ngIf="canSubscribe"></releasenotes-subscription>
    <div *ngIf="metadataObj.template === 'redoc' || metadataObj.template === 'sdk'" class="d-flex justify-content-end">
      <wpbutton mode="getapikey" class="-small" *ngIf="!metadataObj['hide-api-key']">{{ metadataObj['api-key-text'] ? metadataObj['api-key-text'] : 'Get API Key' }}</wpbutton>
      <api-versions-convergence></api-versions-convergence>
    </div>
    <!-- <div *ngIf="metadataObj.template === 'sdk'" class="d-flex justify-content-end">
      <api-versions-convergence></api-versions-convergence>
    </div> -->
  </div>
</div>